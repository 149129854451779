import { defineStore } from 'pinia';
import api from '#lib/api';
export const useBarStore = defineStore('bar', {
    state: () => ({
        bars: null,
        selectedBarID: null,
    }),
    actions: {
        async requestAll() {
            // Reset bars to indicate pending request
            this.bars = null;
            try {
                // Request bars
                const bars = await api.bars.getAll();
                // Map bars by ID
                this.bars = Object.fromEntries(bars.map((bar) => [bar.id, bar]));
            }
            catch (error) {
                // Handle error, or don't and leave request on pending
            }
        },
    },
});
