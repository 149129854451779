const baseURL = process.env.VUE_APP_API_URL;
// HTTP request methods
var Method;
(function (Method) {
    Method["GET"] = "GET";
    Method["POST"] = "POST";
    Method["PUT"] = "PUT";
    Method["DELETE"] = "DELETE";
})(Method || (Method = {}));
// Make a request to an endpoint with know request and response schemas
async function fetchAPI(method, endpoint, data) {
    const response = await fetch(baseURL + endpoint, {
        method,
        body: JSON.stringify(data),
    });
    return await response.json();
}
const bars = {
    getAll: async () => fetchAPI(Method.GET, '/bars'),
};
const brands = {
    getAll: async () => fetchAPI(Method.GET, '/brands'),
};
const api = {
    bars,
    brands,
};
export default api;
