import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify, } from 'vuetify';
const colors = {
    darkbeige: '#DDD290',
    lichtmooibeige: '#f2f2f0',
    mooibeige: '#e8e5d1',
    darkgreen: '#657232',
};
const customTheme = {
    dark: false,
    colors: {
        // white
        // Background
        background: colors.lichtmooibeige,
        // beige
        // Bar list background + menu at the bottom
        surface: colors.mooibeige,
        // beige
        // Dashboard + Bars
        primary: colors.darkgreen,
        darkbeige: colors.darkbeige,
    },
};
export default createVuetify({
    theme: {
        defaultTheme: 'customTheme',
        themes: { customTheme },
    },
});
