import { createRouter as createVueRouter, createWebHistory, } from 'vue-router';
import DashboardView from '#views/DashboardView.vue';
import BarsView from '#views/bars/BarsView.vue';
import BarDetailView from '#views/bars/DetailView.vue';
export * from '#router/meta';
const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardView,
        meta: {
            title: 'Promoties',
        },
    },
    {
        path: '/bars',
        name: 'bars',
        component: BarsView,
        meta: {
            title: 'Bars',
        },
    },
    {
        path: '/bars/details/:barId',
        name: 'details',
        components: {
            default: BarsView,
            sideview: BarDetailView,
        },
        meta: {
            title: 'Details',
            sideview: true,
        },
    },
];
const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;
