import { defineStore } from 'pinia';
import api from '#lib/api';
export const useBrandStore = defineStore('brand', {
    state: () => ({
        brands: null,
    }),
    actions: {
        async requestAll() {
            // Reset brands to indicate pending request
            this.brands = null;
            try {
                // Request brands
                const brands = await api.brands.getAll();
                // Map brands by ID
                this.brands = Object.fromEntries(brands.map((brand) => [brand.id, brand]));
            }
            catch (error) {
                // Handle error, or don't and leave request on pending
            }
        },
    },
});
