import { defineStore } from 'pinia';
import { compareString } from '#lib/util';
import { useBarStore, } from '#stores/bar';
import { useBrandStore, } from '#stores/brand';
import { loadLocalState, updateLocalState, } from '#lib/localState';
export const sortFunctions = {
    price_asc: {
        title: 'Prijs (oplopend)',
        compare: (a, b) => a.price - b.price,
    },
    price_desc: {
        title: 'Prijs (aflopend)',
        compare: (a, b) => b.price - a.price,
    },
    price_litre_asc: {
        title: 'Prijs/L (oplopend)',
        compare: (a, b) => a.price_litre - b.price_litre,
    },
    price_litre_desc: {
        title: 'Prijs/L (aflopend)',
        compare: (a, b) => b.price_litre - a.price_litre,
    },
    volume_asc: {
        title: 'Volume (oplopend)',
        compare: (a, b) => a.volume - b.volume,
    },
    volume_desc: {
        title: 'Volume (aflopend)',
        compare: (a, b) => b.volume - a.volume,
    },
    bar_asc: {
        title: 'Bar (oplopend)',
        compare: (a, b) => compareString(a.bar?.name, b.bar?.name),
    },
    bar_desc: {
        title: 'Bar (aflopend)',
        compare: (a, b) => compareString(b.bar?.name, a.bar?.name),
    },
    brand_asc: {
        title: 'Merk (oplopend)',
        compare: (a, b) => compareString(a.brand?.name, b.brand?.name),
    },
    brand_desc: {
        title: 'Merk (aflopend)',
        compare: (a, b) => compareString(b.brand?.name, a.brand?.name),
    },
};
const localStateMapping = {
    sortType: { storageKey: 'listings/sort-type', defaultValue: 'price_asc' },
    mode: { storageKey: 'listings/price-mode', defaultValue: 'price' },
    brandFilterSelectedIDs: { storageKey: 'listings/filter-brand-IDS', defaultValue: [] },
    volumeFilterSelectedRange: { storageKey: 'listings/filter-volume-range', defaultValue: null },
};
export const useListingStore = defineStore('listing', {
    state: () => ({
        ...loadLocalState(localStateMapping),
        ...{
            searchText: null,
        },
    }),
    getters: {
        linkedListings() {
            const barStore = useBarStore();
            const brandStore = useBrandStore();
            const { bars } = barStore;
            if (bars === null) {
                return null;
            }
            const { brands } = brandStore;
            // Get all listings from all bars
            const listings = Object.values(bars)
                .map((bar) => bar.listings)
                .reduce((allListings, barListings) => [...allListings, ...barListings], []);
            // Set the price per litre, bar and brand for every listing
            return listings.map((listing) => ({
                ...listing,
                price_litre: listing.price / (listing.volume / 100),
                bar: bars?.[listing.barID] ?? null,
                brand: brands?.[listing.brandID] ?? null,
            }));
        },
        preFilteredListings(state) {
            let listings = this.linkedListings;
            if (listings === null) {
                return null;
            }
            // Pre-filter listings
            const { searchText } = state;
            if (searchText !== null) {
                // Bar name should contain searchText
                listings = listings.filter((listing) => listing.bar !== null
                    && listing.bar.name.toLowerCase().includes(searchText.toLowerCase()));
            }
            return listings;
        },
        brandFilterAvailable() {
            const listings = this.preFilteredListings;
            const brandStore = useBrandStore();
            if (listings === null || brandStore.brands === null) {
                return [];
            }
            const listingBrandIDs = listings.map((listing) => listing.brandID);
            const brandIDSet = new Set(listingBrandIDs);
            const brands = Object.values(brandStore.brands)
                .filter((brand) => brandIDSet.has(brand.id));
            return brands;
        },
        volumeFilterAvailableRange() {
            const listings = this.preFilteredListings;
            if (listings === null || listings.length === 0) {
                return null;
            }
            const listingVolumes = listings.map((listing) => listing.volume);
            const min = Math.min(...listingVolumes);
            const max = Math.max(...listingVolumes);
            return { min, max };
        },
        listings(state) {
            let listings = this.preFilteredListings;
            if (listings === null) {
                return null;
            }
            // Further filter listings
            const { brandFilterSelectedIDs, volumeFilterSelectedRange } = state;
            if (brandFilterSelectedIDs.length !== 0) {
                const brandFilterSet = new Set(brandFilterSelectedIDs);
                // Brand ID should be in the set
                listings = listings.filter((listing) => brandFilterSet.has(listing.brandID));
            }
            if (volumeFilterSelectedRange !== null) {
                // Volume should be in range
                listings = listings.filter((listing) => (listing.volume >= volumeFilterSelectedRange.min
                    && listing.volume <= volumeFilterSelectedRange.max));
            }
            // Sort listings using the selected sort type
            listings.sort((sortFunctions[state.sortType] ?? sortFunctions.price_asc).compare);
            return listings;
        },
    },
    actions: {
        clearFilters() {
            this.brandFilterSelectedIDs = [];
            this.volumeFilterSelectedRange = null;
        },
    },
});
window.addEventListener('beforeunload', () => {
    updateLocalState(useListingStore(), localStateMapping);
});
