import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '#App.vue';
import router from '#router';
import vuetify from '#plugins/vuetify';
import webfontloader from '#plugins/webfontloader';
import '#assets/css/main.scss';
const pinia = createPinia();
const app = createApp(App)
    .use(pinia)
    .use(vuetify)
    .use(webfontloader)
    .use(router);
app.mount('#app');
// fix stuff
