function loadLocalStateVariable(storageKey, defaultValue) {
    const value = localStorage.getItem(storageKey);
    if (value === null) {
        return defaultValue;
    }
    return JSON.parse(value);
}
export function loadLocalState(mapping) {
    const localState = {};
    Object.entries(mapping)
        .forEach(([key, variableMapping]) => {
        localState[key] = loadLocalStateVariable(variableMapping.storageKey, variableMapping.defaultValue);
    });
    return localState;
}
function updateLocalStateVariable(storageKey, value) {
    localStorage.setItem(storageKey, JSON.stringify(value));
}
export function updateLocalState(localState, mapping) {
    Object.entries(mapping)
        .forEach(([key, variableMapping]) => {
        updateLocalStateVariable(variableMapping.storageKey, localState[key]);
    });
}
